@import 'posts';

body {
  position: relative;
  padding-top: 60px;
  font-size: 12px;
}

pre {
  border: none;
  background-color: transparent;
}

h1 { font-size: 20px; }
h3 { font-size: 15px; }

@media screen and ( max-width: 700px ) {
  h1 {
     font-size: 20px;
  }
}

@media screen and ( min-width: 700px ) {
  body { font-size: 14px }
  h3 { font-size: 15px }
  pre { font-size: 12px }
  h1 {
     font-size: 25px;
  }
}
